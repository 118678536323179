<template>
  <div class="qooBox-main flex flex-col overflow-hidden" :style="{ height: `${windowsHeight}px` }">
    <MainHeader v-if="$route.meta.layout == 'default'" />
    <div class="grow flex flex-col" :class="{ 'router-view-content': $route.meta.layout == 'default' }"
      :style="{ height: `${windowsHeight - 72}px` }">
      <router-view />
    </div>
  </div>
  <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import MainHeader from "@/components/base/MainHeader";
import { computed, onMounted, watch, onUnmounted } from "vue";
import { useStore } from "@/store/loading.js";
import { useWindowSize } from "@vueuse/core";
import { useWorksheets } from "@/store/worksheet.js";
// import socket from "@/util/socket.js";
import { useRouter, useRoute } from "vue-router";
const { height: windowsHeight } = useWindowSize();

const store = useStore();
const worksheet = useWorksheets();
const router = useRouter();
const route = useRoute();

const isLoading = computed(() => {
  return store.isLoading || worksheet.isLoading;
});

const routerEnum = (function () {
  const router = {
    home: 'floorplans',
    gallery: 'gallery',
    neighbourhood: 'neighbourhood',
    floorplan: 'floorplans'
  };
  return {
    get: function (name) {
      return router[name];
    }
  };
})();

function transformSort(sort) {
  const output = { style: '', type: '' };

  if (sort.name) {
    output.style = sort.name == 'ASC' ? 'Asc' : 'Desc';
    output.type = 'Name';
  } else if (sort.size) {
    output.style = sort.size == 'ASC' ? 'Asc' : 'Desc';
    output.type = 'Size';
  }

  return output;
}

const handleMessage = (stringData) => {
  console.log('handleMessage stringData', stringData, typeof stringData);
  const data = JSON.parse(stringData);
  console.log('handleMessage data', data, typeof data);

  const { page, filters, sort, actions, ...query } = data;

  if (filters) {
    if (filters.area) {
      filters.area = filters.area.split('-').map(Number);
    }
    if (filters.floor) {
      filters.floor = filters.floor.split('-').map(Number);
    }
    query.filter = JSON.stringify(filters);
  }

  if (sort) {
    query.sort = JSON.stringify(transformSort(sort));
  }

  if (actions) {
    query.actions = JSON.stringify(actions);
  }

  const specialPages = {
    floorplan: { full_3d: "false" },
    home: { full_3d: "true" },
  };

  let full_3d = specialPages[page]

  if (specialPages[page] && actions && actions.floorplans) {
    if (actions.floorplans == 'true') {
      full_3d = { full_3d: "both" }
    } else {
      full_3d = specialPages[page]
    }
  }

  if (page) {
    if (specialPages[page]) {
      router.push({
        name: routerEnum.get(page),
        query: { ...full_3d, ...query },
      });
    } else if (router.hasRoute(routerEnum.get(page))) {
      router.push({ name: routerEnum.get(page), query });
    }
  } else if(!page) {
    if(route.name == 'floorplans') {
      router.push({ name: route.name, query: { full_3d: route.query.full_3d ,...query} });
    } else {
      router.push({ name: route.name, query });
    }
  }
};

onMounted(() => {

  socket.on("connect", () => {
    console.log("Connected to the socket server:", socket.id);
  });
  socket.on("message", handleMessage);

  setTimeout(() => {
    // handleMessage(
    //   JSON.stringify({"page":"gallery","actions":{"select_item":"2", "select_category":"balcony"}})
    // );
    // handleMessage(
    //   JSON.stringify({
    //   "page": "neighbourhood",
    //   "filters":{
    //     "category":"Colleges & Universities"
    //   },
    //   "actions":{
    //     "select_category": "Colleges & Universities",
    //     "select_location": "1",
    //     "search":"search by location name",
    //     "zoom_in":"true",
    //     "zoom_out":"true"
    //   }
    // })
    // );
    // setTimeout(() => {
    //   handleMessage({
    //   page: "floorplan",
    //   actions: {
    //     search: "",
    //   },
    // });
    // }, 10000);
    // handleMessage(JSON.stringify({
    //     page: "floorplan",
    //     filters: {
    //       area: "600-1000",
    //     },
    //     sort: {
    //       // size: "DESC",
    //       // name: "DESC",
    //     },
    //     actions: {
    //       // reset_filter: 'true',
    //       // select_switch:"list",
    //       // select_item: '201'
    //     }
    //   }));
    // setTimeout(() => {
    //   handleMessage(JSON.stringify({
    //     // page: "floorplan",
    //     filters: {
    //       // area: "600-1000",
    //     },
    //     sort: {
    //       // size: "DESC",
    //       // name: "DESC",
    //     },
    //     actions: {
    //       reset_filter: true,
    //       // select_switch:"list",
    //       // select_item: '201'
    //     }
    //   }));
    // }, 10000);
    
    // setTimeout(() => {
    //   handleMessage({
    //     page: "home",
    //     actions: {
    //       hidden_suites: "true",
    //     },
    //   });
    // }, 10000);
  }, 20000);

  if (store.numberOfAjaxCAllPending == 0) {
    store.mutationer({ isLoading: false });
  }
});

onUnmounted(() => {
  socket.off("message");
});
</script>


<style>
.g {
  color: #ef45234d;
}
</style>
